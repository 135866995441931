import { createRouter, createWebHistory } from 'vue-router'

import store from '../store'
import { SET_CURRENT_USER } from '../store/mutationTypes';
import { UserRole, UserSubscriptionStatus } from '../utils/constants'

const routes = [
  {
    path: '/signup',
    name: 'SignUp',
    component: () => import(/* webpackChunkName: "signupCrm" */ '../components/crm/auth/AuthComponent.vue'),
  },
  {
    path: '/signin',
    name: 'SignIn',
    component: () => import(/* webpackChunkName: "signinCrm" */ '../components/crm/auth/AuthComponent.vue'),
  },
  {
    path: '/subscriptions',
    name: 'Subscriptions',
    component: () => import(/* webpackChunkName "subscriptions" */ '../components/crm/subscriptions/Subscriptions.vue'),
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: () => import(/* webpackChunkName: "forgotPassword" */ '../components/common/auth/ForgotPassword.vue'),
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: () => import(/* webpackChunkName: "resetPassword" */ '../components/common/auth/ResetPassword.vue'),
  },
  {
    path: '/',
    component: () => import(/* webpackChunkName: "mainCrm" */ '../components/crm/MainCrm.vue'),
    redirect: '/clients',
    children: [
      {
        path: 'clients',
        name: 'Clients',
        // component: () => import(/* webpackChunkName: "clients" */ '../components/crm/clients/Clients.vue'),
        component: () => import(/* webpackChunkName: "clientsPipeline" */ '../components/crm/pipelines/clientsPipeline/ClientsPipeline.vue'),
      },
      {
        path: 'prospects',
        name: 'Prospects',
        // component: () => import(/* webpackChunkName: "prospects" */ '../components/crm/prospects/Prospects.vue'),
        component: () => import(/* webpackChunkName: "prospectPipeline" */ '../components/crm/pipelines/prospectsPipeline/ProspectsPipeline.vue'),
      },
      // {
      //   path: 'pipelines/clients-pipeline',
      //   name: 'ClientsPipeline',
      //   component: () => import(/* webpackChunkName: "clientsPipeline" */ '../components/crm/pipelines/clientsPipeline/ClientsPipeline.vue'),
      // },
      // {
      //   path: 'pipelines/prospects-pipeline',
      //   name: 'ProspectsPipeline',
      //   component: () => import(/* webpackChunkName: "prospectPipeline" */ '../components/crm/pipelines/prospectsPipeline/ProspectsPipeline.vue'),
      // },
      {
        path: 'completed-sales',
        name: 'Completed Sales',
        component: () => import(/* webpackChunkName: "completedSales" */ '../components/crm/pipelines/completedSales/CompletedSales.vue'),
      },
      {
        path: 'reports',
        name: 'Reports',
        component: () => import(/* webpackChunkName: "reports" */ '../components/crm/reports/Reports.vue')
      },
      {
        path: 'archive',
        name: 'Archive',
        component: () => import(/* webpackChunkName: "archive" */ '../components/crm/archive/Archive.vue'),
      },
    ],
  },
  {
    path: '/account',
    name: 'Account',
    component: () => import(/* webpackChunkName: "account" */ '../components/account/Account.vue'),
    redirect: '/account/billing',
    children: [
      {
        path: 'billing',
        name: 'AccountBillings',
        component:() => import(/* webpackChunkName: "usersBilling" */ '../components/account/billing/Billing.vue'),
      },
      {
        path: 'settings',
        name: 'AccountSettings',
        component: () => import(/* webpackChunkName: "usersSettings" */ '../components/common/Settings.vue'),
      },
    ]
  },
  {
    path: '/admin',
    name: 'Admin',
    redirect: '/admin-signin',
    component: () => import(/* webpackChunkName: "admin" */ '../components/admin/Admin.vue'),
    children: [
      {
        path: '/admin-signin',
        name: 'SiginAdmin',
        component: () => import(/* webpackChunkName: "siginAdmin" */ '../components/admin/auth/AdminAuthComponent.vue'),
      },
      {
        path: '/admin-reset-password',
        name: 'ResetPasswordAdmin',
        component: () => import(/* webpackChunkName: "resetPasswordAdmin" */ '../components/common/auth/ResetPassword.vue')
      },
      {
        path: '/admin-forgot-password',
        name: 'ForgotPasswordAdmin',
        component: () => import(/* webpackChunkName: "forgotPasswordAdmin" */ '../components/common/auth/ForgotPassword.vue')
      },
      {
        path: '/admin-panel',
        name: 'AdminContent',
        //beforeEnter:checkAdminRights,
        redirect: 'members',
        component: () => import(/* webpackChunkName: 'adminContent' */ '../components/admin/AdminContent.vue'),
        children: [
          {
            path: '/members',
            name: 'Members',
            component: () => import(/* webpackChunkName: 'adminMembers' */ '../components/admin/members/Members.vue')
          },
          {
            path: '/billing',
            name: 'Billing',
            component: () => import(/* webpackChunkName: 'adminBillings' */ '../components/admin/adminBilling/AdminBilling.vue')
          },
          {
            path: '/settings',
            name: 'Settings',
            component: () => import(/* webpackChunkName: 'adminSettings' */ '../components/admin/adminSettings/AdminSettings.vue')
          }
        ]
      }
    ],
  },
  {
    path: '/b2b/signin',
    name: 'B2BSignIn',
    component: () => import(/* webpackChunkName: "b2bSignin" */ '../components/b2b/auth/B2BAuthComponent.vue'),
  },
  {
    path: '/b2b/signup',
    name: 'B2BSignUp',
    component: () => import(/* webpackChunkName: "b2bSignup" */ '../components/b2b/auth/B2BAuthComponent.vue'),
  },
  {
    path: '/b2b/user-activation',
    name: 'B2BUserActivation',
    component: () => import(/* webpackChunkName: "b2bUseActivation" */ '../components/crm/auth/AuthComponent.vue'),
  },
  {
    path: '/b2b',
    redirect: '/b2b/billings',
    component: () => import(/* webpackChuncName: 'mainB2B' */ '../components/b2b/MainB2B.vue'),
    children: [
      {
        path: 'billings',
        name: 'B2BBillings',
        component: () => import(/* webpackChunkName: "b2bBillings" */ '../components/b2b/billings/B2BBillings.vue'),
      },
      {
        path: 'members',
        name: 'B2BMembers',
        component: () => import(/* webpackChunkName: "b2bMembers" */ '../components/b2b/members/B2BMembers.vue'),
      },
      {
        path: 'members/invite',
        name: 'B2BMembersInvitation',
        component: () => import(/* webpackChunkName: 'b2bMembersInvitaion' */ '../components/b2b/members/B2BMembersInvitation.vue')
      },
      {
        path: 'settings',
        name: 'B2BSettings',
        component: () => import(/* webpackChunkName: "b2bSettings" */ '../components/common/Settings.vue'),
      },
    ],
  },
]

const routesWithoutAuth = [
  'SignIn',
  'SignUp',
  'ForgotPassword',
  'ResetPassword',
  'Admin',
  'SiginAdmin',
  'ResetPasswordAdmin',
  'ForgotPasswordAdmin',
  'B2BSignIn',
  'B2BSignUp',
  'B2BUserActivation',
]
const adminRoutes = [
  'AdminContent',
  'Members',
  'Billing',
  'Settings',
]
const b2bRoutes = [
  'B2BBillings',
  'B2BMembers',
  'B2BMembersInvitation',
  'B2BSettings',
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach((to, from) => {
  const canProceedWithoutAuth = routesWithoutAuth.includes(to.name)
  const isAdminRoute = adminRoutes.includes(to.name)
  const isB2BRoute = b2bRoutes.includes(to.name)
  const currentUser = sessionStorage.getItem('currentUser')
  const isUserInStore = !!store.state.currentUser
  let userRole = UserRole.Initial
  let subscriptionStatus = UserSubscriptionStatus.NotStarted

  if (currentUser) {
    const parsedUser = JSON.parse(currentUser)
    userRole = parsedUser.role
    subscriptionStatus = parsedUser?.subscription?.status
  }

  if (!canProceedWithoutAuth && !currentUser ) {
    if (isAdminRoute) return '/admin-signin'
    return '/signin'
  }

  if (!isUserInStore && currentUser) store.commit(SET_CURRENT_USER, JSON.parse(currentUser))

  if (to.name === 'Subscriptions' && currentUser) {
    if (userRole === UserRole.B2BOwner) return from.fullPath

    if (subscriptionStatus === UserSubscriptionStatus.Active || userRole === UserRole.B2BUserActive) {
      return from.fullPath
    } else {
      return
    }
  }

  if (to.name === 'AccountBillings' && userRole === UserRole.B2BUserActive
      || to.name === 'AccountBillings' && userRole === UserRole.B2BOwner) return { name: 'AccountSettings' }

  if (isAdminRoute) {
    if (userRole === UserRole.Admin) {
      return
    } else {
      return from.fullPath
    }
  }

  if (isB2BRoute) {
    if (userRole === UserRole.B2BOwner) {
      return
    } else {
      if (from.name === 'B2BSignIn') return { name: 'Clients' }
      return from.fullPath
    }
  }
})

export default router
