import axiosInstance from './interceptors'
import {VUE_APP_SERVER_BASE_URL, API_V1, SUBSCRIPTIONS_API, USERS_API, CLIENTS_API} from '../utils/constants'

export const getSubscriptionsVariants = async () => {
  const { data: { data: { products } } } = await axiosInstance.get(
    `${VUE_APP_SERVER_BASE_URL}${API_V1}${SUBSCRIPTIONS_API}/products`
  )

  return products
}

export const getCardList = async () => {
  const { data: { data: { cards } } } = await axiosInstance.get(
    `${VUE_APP_SERVER_BASE_URL}${API_V1}${SUBSCRIPTIONS_API}/cards/list`
  )

  return cards
}

export const attachCard = async cardId => {
  await axiosInstance.post(`${VUE_APP_SERVER_BASE_URL}${API_V1}${SUBSCRIPTIONS_API}/cards/attach/${cardId}`)
}

export const setCardAsDefault = async cardId => {
  await axiosInstance.post(`${VUE_APP_SERVER_BASE_URL}${API_V1}${SUBSCRIPTIONS_API}/cards/default/${cardId}`)
}

export const removeCard = async cardId => {
  await axiosInstance.delete(
    `${VUE_APP_SERVER_BASE_URL}${API_V1}${SUBSCRIPTIONS_API}/cards/delete/${cardId}`
  )
}

export const getUserSubscription = async () => {
  const { data: { data } } = await axiosInstance.get(
    `${VUE_APP_SERVER_BASE_URL}${API_V1}${SUBSCRIPTIONS_API}/subscription`
  )

  return data
}

export const getAlternativePlanData = async () => {
  const { data: { data: { alternative_plan } } } = await axiosInstance.get(
    `${VUE_APP_SERVER_BASE_URL}${API_V1}${SUBSCRIPTIONS_API}/plan/alternative`
  )

  return alternative_plan
}

export const changeSubscriptionPlan = async () => {
  const res = await axiosInstance.post(`${VUE_APP_SERVER_BASE_URL}${API_V1}${SUBSCRIPTIONS_API}/plan/change`)
  return res
}

export const getUpcomingInvoice = async () => {
  const { data: { data: { upcoming_invoice } } } = await axiosInstance.get(
    `${VUE_APP_SERVER_BASE_URL}${API_V1}${SUBSCRIPTIONS_API}/invoice/upcoming`
  )

  return upcoming_invoice
}

export const getBillingHistory = async () => {
  const { data: { data: { payments } } } = await axiosInstance.get(
    `${VUE_APP_SERVER_BASE_URL}${API_V1}${SUBSCRIPTIONS_API}/payments`
  )

  return payments
}

export const getBillingAdminHistory = async  payload => {
  const {pageSize,lastId,order,searchTerm} = payload
  let url = `${VUE_APP_SERVER_BASE_URL}${API_V1}${SUBSCRIPTIONS_API}/billings?last_id=${lastId}&page_size=${pageSize}`
  if (order) url += `&order=${order}`
  if (searchTerm) url += `&search_term=${searchTerm}`

  const { data: { data } } = await axiosInstance.get(url)

  return data
}

export const createStripeSubscription = async payload => {
  const { data: { data } } = await axiosInstance.post(
    `${VUE_APP_SERVER_BASE_URL}${API_V1}${SUBSCRIPTIONS_API}/create-stripe-subscription`,
    payload
  )

  return data
}

export const addB2BMembers = async payload => {
  await axiosInstance.post(
    `${VUE_APP_SERVER_BASE_URL}${API_V1}${SUBSCRIPTIONS_API}/b2b/members/add`,
    payload
  )
}

export const deleteB2BMember = async id => {
  await axiosInstance.delete(`${VUE_APP_SERVER_BASE_URL}${API_V1}${SUBSCRIPTIONS_API}/b2b/members/delete/${id}`)
}

export const cancelSubscription = async () => {
  await axiosInstance.post(
    `${VUE_APP_SERVER_BASE_URL}${API_V1}${SUBSCRIPTIONS_API}/cancel`
  )
}

export const grantedSubscription = async (payload) => {
  const { memberId, dateExpire } = payload
  let url = `${VUE_APP_SERVER_BASE_URL}${API_V1}${SUBSCRIPTIONS_API}/grant/${memberId}`;
  if (dateExpire) {
    url += `?grant_until=${dateExpire}`
  }

  const {data: {data}} = await  axiosInstance.post(url)

  return data
}

export const revokeSubscription = async (payload) => {
  const {data: {data}} = await  axiosInstance.post(
    `${VUE_APP_SERVER_BASE_URL}${API_V1}${SUBSCRIPTIONS_API}/revoke/${payload}`,
  )
  return data
}

export const searchSubscription = async (payload) => {
  const { searchTerm,pageSize,pageNumber } = payload
  let url = `${VUE_APP_SERVER_BASE_URL}${API_V1}${SUBSCRIPTIONS_API}/billings/search?page_size=${pageSize}&search_term=${searchTerm}`;
  if (pageNumber) {
    console.log(pageNumber)
    url+= `&page_number=${pageNumber}`}
  const { data: { data }} = await axiosInstance.get(url)

  return data
}

export const sendPurchaseNotification = async () => {
  const {
    data: { data },
  } = await axiosInstance.post(
    `${VUE_APP_SERVER_BASE_URL}${API_V1}${SUBSCRIPTIONS_API}/send-purchase-notification`
  )

  return data
}
