<template>
  <router-view/>
</template>

<style lang="scss">
html {
  width: 100%;
  height: 100%;

  body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    box-sizing: border-box;

    #app {
      height: 100%;
      width: 100%;
      overflow: hidden;
      font-family: Rubik, sans-serif;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
  }
}
</style>
