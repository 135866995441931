import axios from 'axios'

import axiosInstance from './interceptors'
import {VUE_APP_SERVER_BASE_URL, API_V1, AUTH_API, ADMIN_API} from '../utils/constants'

export const adminSignIn = async payload => {
  const { data: { data: authResposne } } = await axiosInstance.post(
    `${VUE_APP_SERVER_BASE_URL}${API_V1}${AUTH_API}${ADMIN_API}/signin`,
    payload
  )

  return authResposne
}

export const updateTokens = async requestConfig => {
  const tokenUpdate = await axios.get(
    `${VUE_APP_SERVER_BASE_URL}${API_V1}${AUTH_API}/token`,
    requestConfig
  )
  const { data: { data: { tokens } } } = tokenUpdate

  return tokens
}

export const requestPasswordResetLink = async payload => {
  await axiosInstance.post(
    `${VUE_APP_SERVER_BASE_URL}${API_V1}${AUTH_API}/forgot-password`,
    payload
  )
}

export const resetPassword = async payload => {
  const { newPassword, resetToken } = payload
  
  await axios.post(
    `${VUE_APP_SERVER_BASE_URL}${API_V1}${AUTH_API}/reset-password`,
    { new_password: newPassword },
    { headers: { 'X-Reset-Token': resetToken } }
  )
}
