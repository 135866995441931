import axiosInstance from './interceptors'
import {
  API_V1,
  PROSPECTS_API,
  VUE_APP_SERVER_BASE_URL,
} from '../utils/constants'

// Will only fetch prospects with accoding pipeline status (in pipeline/not in pipeline)
// Not in pipeline prospects by default
// TODO: implement separate API calls for general prospects list request
// And list filtered by pipeline status 
export const getProspects = async payload => {
  const { pageNumber, pageSize, order, searchTerm, pipeline_status } = payload
  let url = `${VUE_APP_SERVER_BASE_URL}${API_V1}${PROSPECTS_API}/list/by-pipeline-status?page_number=${pageNumber}`

  if (order) url += `&order=${order}`
  if (pageSize !== 'All') url += `&page_size=${pageSize}`
  if (searchTerm) url += `&search_term=${searchTerm}`
  if (pipeline_status) url += `&pipeline_status=${pipeline_status}`
  
  const { data: { data } } = await axiosInstance.get(url)

  return data
}

export const updateProspectsDefaultOrder = async payload => {
  await axiosInstance.post(
    `${VUE_APP_SERVER_BASE_URL}${API_V1}${PROSPECTS_API}/reorder`,
    payload
  )
}

export const getProspectsArchivePage = async payload => {
  const { lastId, pageSize } = payload

  let url = `${VUE_APP_SERVER_BASE_URL}${API_V1}${PROSPECTS_API}/archive/list?last_id=${lastId}`

  if (pageSize !== 'All') url += `&page_size=${pageSize}`

  const { data: { data } } = await axiosInstance.get(url)

  return data
}

export const searchArchiveProspect = async payload => {
  const { searchValue,pageSize,lastId } = payload
  const { data: { data } } = await axiosInstance.get(
    `${VUE_APP_SERVER_BASE_URL}${API_V1}${PROSPECTS_API}/archive/search?last_id=${lastId}&page_size=${pageSize}&search_term=${searchValue}`,
  )
  
  return data
}

export const createProspect = async prospect => {
  const { data: { data } } = await axiosInstance.post(
    `${VUE_APP_SERVER_BASE_URL}${API_V1}${PROSPECTS_API}/create`,
    prospect
  )

  return data
}

export const updateProspect = async payload => {
  const { prospectId, update } = payload
  const { data: { data } } = await axiosInstance.patch(
    `${VUE_APP_SERVER_BASE_URL}${API_V1}${PROSPECTS_API}/update/${prospectId}`,
    update
  )

  return data
}

export const moveProspectsToClients = async payload => {
  const { data: { data } } = await axiosInstance.patch(
    `${VUE_APP_SERVER_BASE_URL}${API_V1}${PROSPECTS_API}/move-to-client`,
    payload
  )

  return data
}

export const archiveProspects = async payload => {
  const { data: { data } } = await axiosInstance.patch(
    `${VUE_APP_SERVER_BASE_URL}${API_V1}${PROSPECTS_API}/archive`,
    payload
  )

  return data
}

export const restoreProspects = async payload => {
  await axiosInstance.patch(
    `${VUE_APP_SERVER_BASE_URL}${API_V1}${PROSPECTS_API}/restore`,
    payload
  )
}

export const deleteProspects = async payload => {
  const { data: { data } } = await axiosInstance.delete(
    `${VUE_APP_SERVER_BASE_URL}${API_V1}${PROSPECTS_API}/delete`,
    { data: payload }
  )

  return data
}