import axiosInstance from './interceptors'
import { VUE_APP_SERVER_BASE_URL, API_V1, COMPANY_API } from '../utils/constants'

export const getCompany = async () => {
  const { data: { data } } = await axiosInstance.get(`${VUE_APP_SERVER_BASE_URL}${API_V1}${COMPANY_API}`)

  return data
}

export const updateCompany = async payload => {
  await axiosInstance.patch(`${VUE_APP_SERVER_BASE_URL}${API_V1}${COMPANY_API}/update`, payload)
}
