import { DealType } from './constants'

const weekdays = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
]

export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

export const getCurrentDate = () => {
  const date = new Date()
  const day = weekdays[date.getDay()]
  const dayInMonth = date.getDate()
  const month = months[date.getUTCMonth()]
  const year = date.getFullYear()
  const fullDate = `${day}, ${dayInMonth} ${month} ${year}`

  return fullDate
}

export const getPipelineDate = () => {
  const date = new Date()
  const month = months[date.getUTCMonth()]
  const year = date.getFullYear()
  const fullDate =  `${month} ${year}`

  return fullDate
}

export const getBrowserVersion = () => {
  let browserAgent = navigator.userAgent;
  let browserName = navigator.appName;
  let browserVersion = '' + parseFloat(navigator.appVersion);
  let browserMajorVersion = parseInt(navigator.appVersion, 10);
  let Offset, OffsetVersion, ix;

  // For Chrome
  if ((OffsetVersion = browserAgent.indexOf("Chrome")) != -1) {
    browserName = "Chrome";
    browserVersion = browserAgent.substring(OffsetVersion + 7);
  }

  // For Microsoft internet explorer
  else if ((OffsetVersion = browserAgent.indexOf("MSIE")) != -1) {
    browserName = "Microsoft Internet Explorer";
    browserVersion = browserAgent.substring(OffsetVersion + 5);
  }

  // For Firefox
  else if ((OffsetVersion = browserAgent.indexOf("Firefox")) != -1) {
    browserName = "Firefox";
  }

  // For Safari
  else if ((OffsetVersion = browserAgent.indexOf("Safari")) != -1) {
    browserName = "Safari";
    browserVersion = browserAgent.substring(OffsetVersion + 7);
    if ((OffsetVersion = browserAgent.indexOf("Version")) != -1)
      browserVersion = browserAgent.substring(OffsetVersion + 8);
  }

  // For other browser "name/version" is at the end of userAgent
  else if ((Offset = browserAgent.lastIndexOf(' ') + 1) <
    (OffsetVersion = browserAgent.lastIndexOf('/'))) {
    browserName = browserAgent.substring(Offset, OffsetVersion);
    browserVersion = browserAgent.substring(OffsetVersion + 1);
    if (browserName.toLowerCase() == browserName.toUpperCase()) {
      browserName = navigator.appName;
    }
  }

  // Trimming the fullVersion string at
  // semicolon/space if present
  if ((ix = browserVersion.indexOf(";")) != -1)
    browserVersion = browserVersion.substring(0, ix);
  if ((ix = browserVersion.indexOf(" ")) != -1)
    browserVersion = browserVersion.substring(0, ix);


  browserMajorVersion = parseInt('' + browserVersion, 10);
  if (isNaN(browserMajorVersion)) {
    browserVersion = '' + parseFloat(navigator.appVersion);
    browserMajorVersion = parseInt(navigator.appVersion, 10);
  }

  const fullBrowserVersion = (
    `
      <b>Name of Browser</b>: ${browserName};
      <b>Full version</b>: ${browserVersion};
      <b>Major version</b>: ${browserMajorVersion};
      <b>navigator.appName</b>: ${navigator.appName};
      <b>navigator.userAgent</b>: ${navigator.userAgent};
    `
  );

  return fullBrowserVersion
}

export const dragNdropHelper = payload => {
  // console.log('==== dragNDropHelper payload: ', payload);
  const { elementId, oldLocalIndex, newLocalIndex, elementsList } = payload
  const elementGlobalIndex = elementsList.findIndex(i => i.id === elementId)
  const direction = oldLocalIndex > newLocalIndex ? 'up' : 'down'
  const indexDelta = direction === 'up'
    ? oldLocalIndex - newLocalIndex
    : newLocalIndex - oldLocalIndex
  const elementNewIndex = direction === 'up'
    ? elementGlobalIndex - indexDelta
    : elementGlobalIndex + indexDelta
  const shiftedElementOrder = elementsList[elementNewIndex].default_order
  const element = elementsList.splice(elementGlobalIndex, 1)[0]
  const oldElementOrder = element.default_order
  const orderDelta = direction === 'up'
    ? shiftedElementOrder - oldElementOrder
    : oldElementOrder - shiftedElementOrder
  element.default_order = direction === 'up'
    ? element.default_order + orderDelta
    : element.default_order - orderDelta

  elementsList.splice(elementNewIndex, 0, element)

  const start = elementsList.slice(0, elementNewIndex)
  const end = elementsList.slice(elementNewIndex + 1)

  if (direction === 'up') {
    end.forEach(i => i.default_order -= orderDelta)
  } else {
    start.forEach(i => i.default_order += orderDelta)
  }

  const updatedList = [...start, element, ...end]

  return { updatedList, element, oldElementOrder, orderDelta }
}

export const calculateProjectedDealValue = (dealValue, dealType) => {
  let projectedDealValue = 0

  if (dealType === DealType.ClientDeal) {
    projectedDealValue = dealValue * 0.9
  } else if (dealType === DealType.HotProspectDeal) {
    projectedDealValue = dealValue * 0.9
  } else if (dealType === DealType.InProgressProspectDeal) {
    projectedDealValue = dealValue * 0.33
  } else if (dealType === DealType.TargetProspectDeal) {
    projectedDealValue = dealValue * 0.1
  }

  return projectedDealValue
}

export const convertUTCDateTimeToLocal = dateTime => {
  if (!dateTime) return dateTime
      
  const localDate = new Date(dateTime)

  const year = localDate.getFullYear()

  let month = localDate.getMonth() + 1
  month = month < 10 ? '0' + month : month

  let day = localDate.getDate()
  day = day < 10 ? '0' + day : day

  return `${month}/${day}/${year}`
}
