// API server
export const VUE_APP_SERVER_BASE_URL = process.env.VUE_APP_SERVER_BASE_URL;
// API versions
export const API_V1 = "/api/v1";
// Resourses
export const AUTH_API = "/auth";
export const ADMIN_API = "/admin";
export const USERS_API = "/users";
export const CLIENTS_API = "/clients";
export const MEMBERS_API = "/members";
export const PROSPECTS_API = "/prospects";
export const CUSTOMERS_API = "/customers";
export const PIPELINES_API = "/pipelines";
export const REPORTS_API = "/reports";
export const TELEMETRY_API = "/telemetry";
export const SUBSCRIPTIONS_API = "/subscriptions";
export const COMPANY_API = "/company";
// Enums
export const UserRole = Object.freeze({
  Initial: 0,
  User: 1,
  Admin: 2,
  Superadmin: 3,
  B2BUserInactive: 4,
  B2BUserActive: 5,
  B2BAdmin: 6,
  B2BOwner: 7,
  SalesManager: 8,
});
export const CustomerPipelineStatus = Object.freeze({
  0: "Not in Pipeline",
  1: "In Pipeline",
});
export const CustomerPipelineStatusCode = Object.freeze({
  NOT_IN_PIPELINE: 0,
  IN_PIPELINE: 1,
});
export const ProspectStatus = Object.freeze({
  "Not in Pipeline": 0,
  Hot: 1,
  "In Progress": 2,
  Target: 3,
});
export const DealType = Object.freeze({
  HotProspectDeal: 1,
  InProgressProspectDeal: 2,
  TargetProspectDeal: 3,
  ClientDeal: 4,
});
export const UserSubscriptionStatus = Object.freeze({
  NotStarted: 0,
  Granted: 1,
  Incomplete: 2,
  Active: 3,
  Canceled: 4,
  Stopped: 5,
  "Granted Until": 6,
});
