import axiosInstance from './interceptors'
import {
  API_V1,
  CUSTOMERS_API,
  VUE_APP_SERVER_BASE_URL,
} from '../utils/constants'

export const importCustomers = async requestData => {
  const requestConfig = { 
    headers: {
      'Contet-Type': 'multipart/form-data',
    }
  }

  const { data } = await axiosInstance.post(
    `${VUE_APP_SERVER_BASE_URL}${API_V1}${CUSTOMERS_API}/import`,
    requestData,
    requestConfig
  )

  return data
}
