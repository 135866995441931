'use strict';
import {ProspectStatus} from '../utils/constants'

const undoRedoPlugin = (store) => {
  let undoStackItem = {}
  const prevState = store.state
  store.subscribeAction({after:(action,state) => {
      let undoStack = sessionStorage.getItem('undoStack')
      let redoStack = sessionStorage.getItem('redoStack')
      undoStack = JSON.parse(undoStack)
      redoStack = JSON.parse(redoStack)
      if (undoStack && redoStack) {
        if (undoStack.length>0) {
          state.isundoStack = true
        } else {
          state.isundoStack = false
        }
        if (redoStack.length>0) {
          state.isRedoStack = true
        } else {
          state.isRedoStack = false
        }
      }

      let updateData = {}

      switch(action.type) {
        case 'createClient':
          updateData = state.clients.clientsList.find(client => client.name == action.payload.new_client.name)
          undoStackItem = {
            type: action.type,
            data: updateData.id
          }
          undoStack = sessionStorage.getItem('undoStack')
          undoStack = JSON.parse(undoStack)
          if (undoStack.length >= 20) {
            undoStack.pop()
            undoStack.unshift(undoStackItem)
          }
          undoStack.unshift(undoStackItem)
          sessionStorage.setItem('undoStack', JSON.stringify(undoStack))
          state.isundoStack = true;
          break;
        case 'createProspect':
          updateData = state.prospects.prospectsList.find(prospect => prospect.name == action.payload.new_prospect.name)
          undoStackItem = {
            type: action.type,
            data: updateData.id
          }
          undoStack = sessionStorage.getItem('undoStack')
          undoStack = JSON.parse(undoStack)
          if (undoStack.length >= 20) {
            undoStack.pop()
            undoStack.unshift(undoStackItem)
          }
          undoStack.unshift(undoStackItem)
          sessionStorage.setItem('undoStack', JSON.stringify(undoStack))
          state.isundoStack = true;
          break;
        case 'createClientAndDeal':
          updateData = state.clientDeals.clientDealsList.find(
            clientDeal => clientDeal.subject_name == action.payload.new_client.name
          )

          if (updateData) {
            undoStackItem = {
              type: action.type,
              data: updateData.id,
              id_subject: updateData.subject
            }
          } else {
            updateData = state.clients.clientsList.find(
              client => client.name === action.payload.new_client.name
            )

            undoStackItem = {
              type: action.type,
              data: null,
              id_subject: updateData.id
            }
          }

          undoStack = sessionStorage.getItem('undoStack')
          undoStack = JSON.parse(undoStack)

          if (undoStack.length >= 20) {
            undoStack.pop()
            undoStack.unshift(undoStackItem)
          }

          undoStack.unshift(undoStackItem)
          sessionStorage.setItem('undoStack', JSON.stringify(undoStack))
          state.isundoStack = true;

          break;
        case 'createProspectAndDeal':
          const statusDeals = action.payload.new_prospect.status
          if (statusDeals === ProspectStatus.Target) {
            updateData = state.targetDeals.targetDealsList.find(targetDeals => targetDeals.subject_name === action.payload.new_prospect.name)
            undoStackItem = {
              type: action.type,
              data: updateData.id,
              id_subject: updateData.subject,
              statusDeals: statusDeals
            }
            undoStack = sessionStorage.getItem('undoStack')
            undoStack = JSON.parse(undoStack)
            
            if (undoStack.length >= 20) {
            undoStack.pop()
            undoStack.unshift(undoStackItem)
            }
            undoStack.unshift(undoStackItem)
            sessionStorage.setItem('undoStack', JSON.stringify(undoStack))
            state.isundoStack = true;
            break;
          } else if (statusDeals === ProspectStatus['In Progress']) {
            updateData = state.inProgressDeals.inProgressDealsList.find(inProgressDeal => inProgressDeal.subject_name === action.payload.new_prospect.name)
            undoStackItem = {
              type: action.type,
              data: updateData.id,
              id_subject: updateData.subject,
              statusDeals: statusDeals
            }
            undoStack = sessionStorage.getItem('undoStack')
            undoStack = JSON.parse(undoStack)
            if (undoStack.length >= 20) {
            undoStack.pop()
            undoStack.unshift(undoStackItem)
            }
            undoStack.unshift(undoStackItem)
            sessionStorage.setItem('undoStack', JSON.stringify(undoStack))
            state.isundoStack = true;
            break;
          } else if (statusDeals === ProspectStatus.Hot) {
            updateData = state.hotDeals.hotDealsList.find(hotDeals => hotDeals.subject_name === action.payload.new_prospect.name)
            undoStackItem = {
              type: action.type,
              data: updateData.id,
              id_subject: updateData.subject,
              statusDeals: statusDeals
            }
            undoStack = sessionStorage.getItem('undoStack')
            undoStack = JSON.parse(undoStack)
            if (undoStack.length >= 20) {
            undoStack.pop()
            undoStack.unshift(undoStackItem)
            }
            undoStack.unshift(undoStackItem)
            sessionStorage.setItem('undoStack', JSON.stringify(undoStack))
            state.isundoStack = true;
            break;
          } else if (statusDeals === ProspectStatus['Not in Pipeline']) {
            break;
          }
          break;
        default:
          break;
      }
    }
  })
  store.subscribeAction((action,state)=>{
    let undoStack = sessionStorage.getItem('undoStack')
    let redoStack = sessionStorage.getItem('redoStack')
    if (undoStack && redoStack) {
      if (undoStack.length>0 ) {
        state.isundoStack = true
      } else {
        state.isundoStack = false
      }
      if (redoStack.length>0) {
        state.isRedoStack = true
      } else {
        state.isRedoStack = false
      }
    }
    let updateData = {}
    switch(action.type) {
      case 'updateClient':
        updateData = prevState.clients.clientsList.find(client => client.id === action.payload.id)
        undoStackItem = {
          type: action.type,
          data: updateData,
          next_data: action.payload,
        }
        undoStack = JSON.parse(undoStack)
        if (undoStack.length >= 20) {
          undoStack.pop()
          undoStack.unshift(undoStackItem)
        }
        undoStack.unshift(undoStackItem)
        sessionStorage.setItem('undoStack',JSON.stringify(undoStack))
        break;
      case 'updateProspect':
        updateData = prevState.prospects.prospectsList.find(prospect => prospect.id === action.payload.id)
        undoStackItem = {
          type: action.type,
          data: updateData,
          next_data: action.payload,
        }
        undoStack = JSON.parse(undoStack)
        if (undoStack.length >= 20) {
          undoStack.pop()
          undoStack.unshift(undoStackItem)
        }
        undoStack.unshift(undoStackItem)
        sessionStorage.setItem('undoStack',JSON.stringify(undoStack))
        break;
      case 'updateClientDealValue':
        updateData = prevState.clientDeals.clientDealsList.find(clientDeals => clientDeals.id === action.payload.id)
        undoStackItem = {
          type: action.type,
          data: updateData,
          next_data: action.payload,
        }
        undoStack = JSON.parse(undoStack)
        if (undoStack.length >= 20) {
          undoStack.pop()
          undoStack.unshift(undoStackItem)
        }
        undoStack.unshift(undoStackItem)
        sessionStorage.setItem('undoStack',JSON.stringify(undoStack))
        break;
      case 'updateHotDealValue':
        updateData = prevState.hotDeals.hotDealsList.find(hotDeals => hotDeals.id === action.payload.id)
        undoStackItem = {
          type: action.type,
          data: updateData,
          next_data: action.payload,
        }
        undoStack = JSON.parse(undoStack)
        if (undoStack.length >= 20) {
          undoStack.pop()
          undoStack.unshift(undoStackItem)
        }
        undoStack.unshift(undoStackItem)
        sessionStorage.setItem('undoStack',JSON.stringify(undoStack))
        break;
      case 'updateTargetDealValue':
        updateData = prevState.targetDeals.targetDealsList.find(targetDeals => targetDeals.id === action.payload.id)
        undoStackItem = {
          type: action.type,
          data: updateData,
          next_data: action.payload,
        }
        undoStack = JSON.parse(undoStack)
        if (undoStack.length >= 20) {
          undoStack.pop()
          undoStack.unshift(undoStackItem)
        }
        undoStack.unshift(undoStackItem)
        sessionStorage.setItem('undoStack',JSON.stringify(undoStack))
        break;
      case 'updateInProgressDealValue':
        updateData = prevState.inProgressDeals.inProgressDealsList.find(inProgressDeals => inProgressDeals.id === action.payload.id)
        undoStackItem = {
          type: action.type,
          data: updateData,
          next_data: action.payload,
        }
        undoStack = JSON.parse(undoStack)
        if (undoStack.length >= 20) {
          undoStack.pop()
          undoStack.unshift(undoStackItem)
        }
        undoStack.unshift(undoStackItem)
        sessionStorage.setItem('undoStack',JSON.stringify(undoStack))
        break;
      case 'moveClientsToProspects':
        undoStackItem = {
          type: action.type,
          data: action.payload
        }
        undoStack = sessionStorage.getItem('undoStack')
        undoStack = JSON.parse(undoStack)
        if (undoStack.length >= 20) {
          undoStack.pop()
          undoStack.unshift(undoStackItem)
        }
        undoStack.unshift(undoStackItem)
        sessionStorage.setItem('undoStack', JSON.stringify(undoStack))
        break;
      case 'archiveClients':
        undoStackItem = {
          type: action.type,
          data: action.payload
        }
        undoStack = sessionStorage.getItem('undoStack')
        undoStack = JSON.parse(undoStack)
        if (undoStack.length >= 20) {
          undoStack.pop()
          undoStack.unshift(undoStackItem)
        }
        undoStack.unshift(undoStackItem)
        sessionStorage.setItem('undoStack', JSON.stringify(undoStack))
        break;
      case 'triggerPlaginOnDealsCreation': 
        undoStackItem = {
          type: 'AddDeals',
          deals_ids: action.payload.deal_ids,
          subject_ids: {subject_ids:action.payload.subject_ids},
        }
        undoStack = sessionStorage.getItem('undoStack')
        undoStack = JSON.parse(undoStack)
        if (undoStack.length >= 20) {
          undoStack.pop()
          undoStack.unshift(undoStackItem)
        }
        undoStack.unshift(undoStackItem)
        sessionStorage.setItem('undoStack', JSON.stringify(undoStack))
        break;
      case 'updateProspect':
        updateData = prevState.prospects.prospectsList.find(prospect => prospect.id === action.payload.id)
        undoStackItem = {
          type: action.type,
          data: updateData,
          next_data: action.payload,
        }
        undoStack = JSON.parse(undoStack)
        if (undoStack.length >= 20) {
          undoStack.pop()
          undoStack.unshift(undoStackItem)
        }
        undoStack.unshift(undoStackItem)
        sessionStorage.setItem('undoStack',JSON.stringify(undoStack))
        break;
      case 'moveProspectsToClients':
        undoStackItem = {
          type: action.type,
          data: action.payload
        }
        undoStack = JSON.parse(undoStack)
        if (undoStack.length >= 20) {
          undoStack.pop()
          undoStack.unshift(undoStackItem)
        }
        undoStack.unshift(undoStackItem)
        sessionStorage.setItem('undoStack',JSON.stringify(undoStack))
      case 'archiveProspects':
        undoStackItem = {
          type: action.type,
          data: action.payload
        }
        undoStack = sessionStorage.getItem('undoStack')
        undoStack = JSON.parse(undoStack)
        if (undoStack.length >= 20) {
          undoStack.pop()
          undoStack.unshift(undoStackItem)
        }
        undoStack.unshift(undoStackItem)
        sessionStorage.setItem('undoStack', JSON.stringify(undoStack))
        break;
      case 'removeClientDeals':
        undoStackItem = {
          type: action.type,
          data: action.payload
        }
        undoStack = sessionStorage.getItem('undoStack')
        undoStack = JSON.parse(undoStack)
        if (undoStack.length >= 20) {
          undoStack.pop()
          undoStack.unshift(undoStackItem)
        }
        undoStack.unshift(undoStackItem)
        sessionStorage.setItem('undoStack', JSON.stringify(undoStack))
        break;
      case 'openClientDeal':
        undoStackItem = {
          type: action.type,
          data: action.payload
        }
        undoStack = sessionStorage.getItem('undoStack')
        undoStack = JSON.parse(undoStack)
        if (undoStack.length >= 20) {
          undoStack.pop()
          undoStack.unshift(undoStackItem)
        }
        undoStack.unshift(undoStackItem)
        sessionStorage.setItem('undoStack', JSON.stringify(undoStack))
        break;
      case 'closeClientDeal':
        undoStackItem = {
          type: action.type,
          data: action.payload
        }
        undoStack = sessionStorage.getItem('undoStack')
        undoStack = JSON.parse(undoStack)
        if (undoStack.length >= 20) {
          undoStack.pop()
          undoStack.unshift(undoStackItem)
        }
        undoStack.unshift(undoStackItem)
        sessionStorage.setItem('undoStack', JSON.stringify(undoStack))
        break;
      case 'triggerDeletedClientDeals' : 
        undoStackItem = {
          type: 'removeClientDeals',
          deal_ids: action.payload.deal_ids,
          subject_ids: {subject_ids:action.payload.subject_ids}
        }
        undoStack = sessionStorage.getItem('undoStack')
        undoStack = JSON.parse(undoStack)
        if (undoStack.length >= 20) {
          undoStack.pop()
          undoStack.unshift(undoStackItem)
        }
        undoStack.unshift(undoStackItem)
        sessionStorage.setItem('undoStack', JSON.stringify(undoStack))
        break;
      case 'moveClientsPipelineToProspects':
        undoStackItem = {
          type: action.type,
          data: action.payload
        }
        undoStack = sessionStorage.getItem('undoStack')
        undoStack = JSON.parse(undoStack)
        if (undoStack.length >= 20) {
          undoStack.pop()
          undoStack.unshift(undoStackItem)
        }
        undoStack.unshift(undoStackItem)
        sessionStorage.setItem('undoStack', JSON.stringify(undoStack))
        break;
      case 'removeTargetDeals':
        undoStackItem = {
          type: action.type,
          data: action.payload
        }
        undoStack = sessionStorage.getItem('undoStack')
        undoStack = JSON.parse(undoStack)
        if (undoStack.length >= 20) {
          undoStack.pop()
          undoStack.unshift(undoStackItem)
        }
        undoStack.unshift(undoStackItem)
        sessionStorage.setItem('undoStack', JSON.stringify(undoStack))
        break;
      // case 'removeHotDeals':
      //   undoStackItem = {
      //     type: action.type,
      //     data: action.payload
      //   }
      //   undoStack = sessionStorage.getItem('undoStack')
      //   undoStack = JSON.parse(undoStack)
      //   if (undoStack.length >= 20) {
      //     undoStack.pop()
      //     undoStack.unshift(undoStackItem)
      //   }
      //   undoStack.unshift(undoStackItem)
      //   sessionStorage.setItem('undoStack', JSON.stringify(undoStack))
      //   break;
      // case 'removeInProgressDeals':
      //   undoStackItem = {
      //     type: action.type,
      //     data: action.payload
      //   }
      //   undoStack = sessionStorage.getItem('undoStack')
      //   undoStack = JSON.parse(undoStack)
      //   if (undoStack.length >= 20) {
      //     undoStack.pop()
      //     undoStack.unshift(undoStackItem)
      //   }
      //   undoStack.unshift(undoStackItem)
      //   sessionStorage.setItem('undoStack', JSON.stringify(undoStack))
      //   break;
      case 'openInProgressDeal':
        undoStackItem = {
          type: action.type,
          data: action.payload
        }
        undoStack = sessionStorage.getItem('undoStack')
        undoStack = JSON.parse(undoStack)
        if (undoStack.length >= 20) {
          undoStack.pop()
          undoStack.unshift(undoStackItem)
        }
        undoStack.unshift(undoStackItem)
        sessionStorage.setItem('undoStack', JSON.stringify(undoStack))
        break;
      case 'closeInProgressDeal':
        undoStackItem = {
          type: action.type,
          data: action.payload
        }
        undoStack = sessionStorage.getItem('undoStack')
        undoStack = JSON.parse(undoStack)
        if (undoStack.length >= 20) {
          undoStack.pop()
          undoStack.unshift(undoStackItem)
        }
        undoStack.unshift(undoStackItem)
        sessionStorage.setItem('undoStack', JSON.stringify(undoStack))
        break;
      case 'openHotDeal':
        undoStackItem = {
          type: action.type,
          data: action.payload
        }
        undoStack = sessionStorage.getItem('undoStack')
        undoStack = JSON.parse(undoStack)
        if (undoStack.length >= 20) {
          undoStack.pop()
          undoStack.unshift(undoStackItem)
        }
        undoStack.unshift(undoStackItem)
        sessionStorage.setItem('undoStack', JSON.stringify(undoStack))
        break;
      case 'closeHotDeal':
        undoStackItem = {
          type: action.type,
          data: action.payload
        }
        undoStack = sessionStorage.getItem('undoStack')
        undoStack = JSON.parse(undoStack)
        if (undoStack.length >= 20) {
          undoStack.pop()
          undoStack.unshift(undoStackItem)
        }
        undoStack.unshift(undoStackItem)
        sessionStorage.setItem('undoStack', JSON.stringify(undoStack))
        break;
      case 'openTargetDeal':
        undoStackItem = {
          type: action.type,
          data: action.payload
        }
        undoStack = sessionStorage.getItem('undoStack')
        undoStack = JSON.parse(undoStack)
        if (undoStack.length >= 20) {
          undoStack.pop()
          undoStack.unshift(undoStackItem)
        }
        undoStack.unshift(undoStackItem)
        sessionStorage.setItem('undoStack', JSON.stringify(undoStack))
        break;
      case 'closeTargetDeal':
        undoStackItem = {
          type: action.type,
          data: action.payload
        }
        undoStack = sessionStorage.getItem('undoStack')
        undoStack = JSON.parse(undoStack)
        if (undoStack.length >= 20) {
          undoStack.pop()
          undoStack.unshift(undoStackItem)
        }
        undoStack.unshift(undoStackItem)
        sessionStorage.setItem('undoStack', JSON.stringify(undoStack))
        break;
      case 'moveTargetDealToHot':
        undoStackItem = {
          type: action.type,
          data: action.payload
        }
        undoStack = sessionStorage.getItem('undoStack')
        undoStack = JSON.parse(undoStack)
        if (undoStack.length >= 20) {
          undoStack.pop()
          undoStack.unshift(undoStackItem)
        }
        undoStack.unshift(undoStackItem)
        sessionStorage.setItem('undoStack', JSON.stringify(undoStack))
        break;
      case 'moveTargetDealToInProgress':
        undoStackItem = {
          type: action.type,
          data: action.payload
        }
        undoStack = sessionStorage.getItem('undoStack')
        undoStack = JSON.parse(undoStack)
        if (undoStack.length >= 20) {
          undoStack.pop()
          undoStack.unshift(undoStackItem)
        }
        undoStack.unshift(undoStackItem)
        sessionStorage.setItem('undoStack', JSON.stringify(undoStack))
        break;
      case 'moveHotDealToInProgress':
        undoStackItem = {
          type: action.type,
          data: action.payload
        }
        undoStack = sessionStorage.getItem('undoStack')
        undoStack = JSON.parse(undoStack)
        if (undoStack.length >= 20) {
          undoStack.pop()
          undoStack.unshift(undoStackItem)
        }
        undoStack.unshift(undoStackItem)
        sessionStorage.setItem('undoStack', JSON.stringify(undoStack))
        break;
      case 'moveHotDealToTarget':
        undoStackItem = {
          type: action.type,
          data: action.payload
        }
        undoStack = sessionStorage.getItem('undoStack')
        undoStack = JSON.parse(undoStack)
        if (undoStack.length >= 20) {
          undoStack.pop()
          undoStack.unshift(undoStackItem)
        }
        undoStack.unshift(undoStackItem)
        sessionStorage.setItem('undoStack', JSON.stringify(undoStack))
        break;
      case 'moveInProgressDealToHot':
        undoStackItem = {
          type: action.type,
          data: action.payload
        }
        undoStack = sessionStorage.getItem('undoStack')
        undoStack = JSON.parse(undoStack)
        if (undoStack.length >= 20) {
          undoStack.pop()
          undoStack.unshift(undoStackItem)
        }
        undoStack.unshift(undoStackItem)
        sessionStorage.setItem('undoStack', JSON.stringify(undoStack))
        break;
      case 'moveInProgressDealToTarget':
        undoStackItem = {
          type: action.type,
          data: action.payload
        }
        undoStack = sessionStorage.getItem('undoStack')
        undoStack = JSON.parse(undoStack)
        if (undoStack.length >= 20) {
          undoStack.pop()
          undoStack.unshift(undoStackItem)
        }
        undoStack.unshift(undoStackItem)
        sessionStorage.setItem('undoStack', JSON.stringify(undoStack))
        break;
      case 'restoreClients':
        undoStackItem = {
          type: action.type,
          data: action.payload
        }
        undoStack = sessionStorage.getItem('undoStack')
        undoStack = JSON.parse(undoStack)
        if (undoStack.length >= 20) {
          undoStack.pop()
          undoStack.unshift(undoStackItem)
        }
        undoStack.unshift(undoStackItem)
        sessionStorage.setItem('undoStack', JSON.stringify(undoStack))
        break;
      case 'restoreProspects':
        undoStackItem = {
          type: action.type,
          data: action.payload
        }
        undoStack = sessionStorage.getItem('undoStack')
        undoStack = JSON.parse(undoStack)
        if (undoStack.length >= 20) {
          undoStack.pop()
          undoStack.unshift(undoStackItem)
        }
        undoStack.unshift(undoStackItem)
        sessionStorage.setItem('undoStack', JSON.stringify(undoStack))
        break;
      case 'updateClientsOrder':
        undoStackItem = {
          type: action.type,
          data: action.payload,
        }
        undoStack = sessionStorage.getItem('undoStack')
        undoStack = JSON.parse(undoStack)
        if (undoStack.length >= 20) {
          undoStack.pop()
          undoStack.unshift(undoStackItem)
        }
        undoStack.unshift(undoStackItem)
        sessionStorage.setItem('undoStack', JSON.stringify(undoStack))
        break;
      case 'updateProspectsOrder':
        undoStackItem = {
          type: action.type,
          data: action.payload
        }
        undoStack = sessionStorage.getItem('undoStack')
        undoStack = JSON.parse(undoStack)
        if (undoStack.length >= 20) {
          undoStack.pop()
          undoStack.unshift(undoStackItem)
        }
        undoStack.unshift(undoStackItem)
        sessionStorage.setItem('undoStack', JSON.stringify(undoStack))
        break;
      case 'updateDealsOrder': 
        undoStackItem = {
          type: action.type,
          data: action.payload
        }
        undoStack = sessionStorage.getItem('undoStack')
        undoStack = JSON.parse(undoStack)
        if (undoStack.length >= 20) {
          undoStack.pop()
          undoStack.unshift(undoStackItem)
        }
        undoStack.unshift(undoStackItem)
        sessionStorage.setItem('undoStack', JSON.stringify(undoStack))
        break;
      default:
        break;
    }
  })
}
export default undoRedoPlugin