// Users
export const SET_CURRENT_USER = 'SET_CURRENT_USER'
export const CLEAR_CURRENT_USER = 'CLEAR_CURRENT_USER'
export const UPDATE_CURRENT_USER = 'UPDATE_CURRENT_USER'
// Company
export const SET_COMPANY = 'SET_COMPANY'
export const UPDATE_COMPANY = 'UPDATE_COMPANY'
export const CLEAR_COMPANY = 'CLEAR_COMPANY'
// Members
export const ADD_MEMBER = 'ADD_MEMBER'
export const UPDATE_MEMBER = 'UPDATE_MEMBER'
export const UPDATE_MEMBERS_LIST = 'UPDATE_MEMBERS_LIST'
export const UPDATE_MEMBERS_COUNT = 'UPDATE_MEMBERS_COUNT'
export const REPLACE_MEMBERS_LIST = 'REPLACE_MEMBERS_LIST'
export const REMOVE_USERS_FROM_LIST = 'REMOVE_USERS_FROM_LIST'
export const UPDATE_ATTACHED_USERS = 'UPDATE_ATTACHED_USERS'
export const UPDATE_USERS_TO_ATTACH_LIST = 'UPDATE_USERS_TO_ATTACH_LIST'
// B2B Members
export const SET_B2B_MEMBERS_LIST = 'SET_B2B_MEMBERS_LIST'
export const DELETE_B2B_MEMBER = 'DELETE_B2B_MEMBER'
// Clients
export const ADD_CLIENT = 'ADD_CLIENT'
export const UPDATE_CLIENTS_LIST = 'UPDATE_CLIENTS_LIST'
export const UPDATE_CLIENTS_COUNT = 'UPDATE_CLIENTS_COUNT'
export const UPDATE_CLIENTS_ARCHIVE_LIST = 'UPDATE_CLIENTS_ARCHIVE_LIST'
export const UPDATE_CLIENTS_ARCHIVE_COUNT = 'UPDATE_CLIENTS_ARCHIVE_COUNT'
export const UPDATE_CLIENT = 'UPDATE_CLIENT'
export const REMOVE_CLIENTS_FROM_LIST = 'REMOVE_CLIENTS_FROM_LIST'
export const CLEAR_CLIENTS = 'CLEAR_CLIENTS'
export const REPLACE_CLIENTS_LIST = 'REPLACE_CLIENTS_LIST'
export const REPLACE_CLIENTS_ARCHIVE_LIST = 'REPLACE_CLIENTS_ARCHIVE_LIST'
export const REMOVE_CLIENTS_FROM_ARCHIVE_LIST =
  'REMOVE_CLIENTS_FROM_ARCHIVE_LIST'
export const CLEAR_ARCHIVE_CLIENTS = 'CLEAR_ARCHIVE_CLIENTS'
// Prospects
export const ADD_PROSPECT = 'ADD_PROSPECT'
export const UPDATE_PROSPECTS_LIST = 'UPDATE_PROSPECTS_LIST'
export const UPDATE_PROSPECTS_COUNT = 'UPDATE_PROSPECTS_COUNT'
export const UPDATE_PROSPECTS_ARCHIVE_LIST = 'UPDATE_PROSPECTS_ARCHIVE_LIST'
export const UPDATE_PROSPECTS_ARCHIVE_COUNT = 'UPDATE_PROSPECTS_ARCHIVE_COUNT'
export const UPDATE_PROSPECT = 'UPDATE_PROSPECT'
export const REMOVE_PROSPECTS_FROM_LIST = 'REMOVE_PROSPECTS_FROM_LIST'
export const CLEAR_PROSPECTS = 'CLEAR_PROSPECTS'
export const REPLACE_PROSPECTS_LIST = 'REPLACE_PROSPECTS_LIST'
export const REPLACE_PROSPECTS_ARCHIVE_LIST = 'REPLACE_PROSPECTS_ARCHIVE_LIST'
export const REMOVE_PROSPECTS_FROM_ARCHIVE_LIST =
  'REMOVE_PROSPECTS_FROM_ARCHIVE_LIST'
export const CLEAR_ARCHIVE_PROSPECTS = 'CLEAR_ARCHIVE_PROSPECTS'
// Pipelines
export const SET_ACTIVE_PIPELINE = 'SET_ACTIVE_PIPELINE'
export const CLEAR_ACTIVE_PIPELINE = 'CLEAR_ACTIVE_PIPELINE'
export const UPDATE_PIPELINE_FIELDS = 'UPDATE_PIPELINE_FIELDS'
export const UPDATE_PIPELINE_VALUE = 'UPDATE_PIPELINE_VALUE'
export const UPDATE_PIPELINE_CLOSED_VALUE = 'UPDATE_PIPELINE_CLOSED_VALUE'
export const SET_PIPELINE_LIST = 'SET_PIPELINE_LIST'
export const UPDATE_PIPELINES_LIST = 'UPDATE_PIPELINES_LIST'
export const UPDATE_PIPELINES_COUNT = 'UPDATE_PIPELINES_COUNT'
// Clients pipeline
export const ADD_CLIENT_DEAL = 'ADD_CLIENT_DEAL'
export const UPDATE_CLIENT_DEALS_LIST = 'UPDATE_CLIENT_DEALS_LIST'
export const UPDATE_CLIENT_DEALS_COUNT = 'UPDATE_CLIENT_DEALS_COUNT'
export const UPDATE_CLIENT_DEAL = 'UPDATE_CLIENT_DEAL'
export const REMOVE_CLIENT_DEALS = 'REMOVE_CLIENT_DEALS'
export const REMOVE_CLIENTS_DEALS = 'REMOVE_CLIENTS_DEALS'
export const CLEAR_CLIENT_DEALS = 'CLEAR_CLIENT_DEALS'
export const REPLACE_CLIENT_DEALS_LIST = 'REPLACE_CLIENT_DEALS_LIST'
// Prospects pipeline
// Hot
export const ADD_HOT_DEAL = 'ADD_HOT_DEAL'
export const SET_HOT_DEALS_LIST = 'SET_HOT_DEALS_LIST'
export const UPDATE_HOT_DEALS_LIST = 'UPDATE_HOT_DEALS_LIST'
export const UPDATE_HOT_DEALS_COUNT = 'UPDATE_HOT_DEALS_COUNT'
export const UPDATE_HOT_DEAL = 'UPDATE_HOT_DEAL'
export const REMOVE_HOT_DEALS = 'REMOVE_HOT_DEALS'
export const REPLACE_HOT_DEALS = 'REPLACE_HOT_DEALS'
export const CLEAR_HOT_DEALS = 'CLEAR_HOT_DEALS'
// In Progress
export const ADD_IN_PROGRESS_DEAL = 'ADD_IN_PROGRESS_DEAL'
export const SET_IN_PROGRESS_DEALS_LIST = 'SET_IN_PROGRESS_DEALS_LIST'
export const UPDATE_IN_PROGRESS_DEALS_LIST = 'UPDATE_IN_PROGRESS_DEALS_LIST'
export const UPDATE_IN_PROGRESS_DEALS_COUNT = 'UPDATE_IN_PROGRESS_DEALS_COUNT'
export const UPDATE_IN_PROGRESS_DEAL = 'UPDATE_IN_PROGRESS_DEAL'
export const REMOVE_IN_PROGRESS_DEALS = 'REMOVE_IN_PROGRESS_DEALS'
export const CLEAR_IN_PROGRESS_DEALS = 'CLEAR_IN_PROGRESS_DEALS'
// Target
export const ADD_TARGET_DEAL = 'ADD_TARGET_DEAL'
export const SET_TARGET_DEALS_LIST = 'SET_TARGET_DEALS_LIST'
export const UPDATE_TARGET_DEALS_LIST = 'UPDATE_TARGET_DEALS_LIST'
export const UPDATE_TARGET_DEALS_COUNT = 'UPDATE_TARGET_DEALS_COUNT'
export const UPDATE_TARGET_DEAL = 'UPDATE_TARGET_DEAL'
export const REMOVE_TARGET_DEALS = 'REMOVE_TARGET_DEALS'
export const CLEAR_TARGET_DEALS = 'CLEAR_TARGET_DEALS'
// All
export const CLEAR_ALL_PROSPECT_DEALS = 'CLEAR_ALL_PROSPECT_DEALS'
// CompletedSales
export const UPDATE_COMPLETED_SALES_LIST = 'UPDATE_COMPLETED_SALES_LIST'
export const UPDATE_COMPLETED_SALES_COUNT = 'UPDATE_COMPLETED_SALES_COUNT'
export const CLEAR_COMPLETED_SALES = 'CLEAR_COMPLETED_SALES'
//Subscriptions
export const SET_CARD_LIST = 'SET_CARD_LIST'
export const ADD_CARD = 'ADD_CARD'
export const DELETE_CARD = 'DELETE_CARD'
export const UPDATE_BILLINGS_LIST = 'UPDATE_BILLINGS_LIST'
export const UPDATE_BILLINGS_COUNT = 'UPDATE_BILLINGS_COUNT'
export const GRANT_SUBSCRIPTION = 'GRANT_SUBSCRIPTION'
export const REVOKE_SUBSCRIPTION = 'REVOKE_SUBSCRIPTION'
export const REPLACE_BILLINGS_LIST = 'REPLACE_BILLINGS_LIST'
export const CLEAR_ADMIN_MEMBERS = 'CLEAR_ADMIN_MEMBERS'
export const CLEAR_ADMIN_BILLINGS = 'CLEAR_ADMIN_BILLINGS'
// UndoRedo
export const UNDO_REDO_UPDATE_CLIENT = 'UNDO_REDO_UPDATE_CLIENT'
