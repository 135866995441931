import axiosInstance from './interceptors'
import {
  VUE_APP_SERVER_BASE_URL,
  API_V1,
  REPORTS_API,
} from '../utils/constants'

export const getMonthReportHtml = async (pipelineId) => {
  const { data } = await axiosInstance.get(
    `${VUE_APP_SERVER_BASE_URL}${API_V1}${REPORTS_API}/month/${pipelineId}`
  )
  return data
}

export const generateMemberReportHtml = async (pipelineId, userId) => {
  const { data } = await axiosInstance.get(
    `${VUE_APP_SERVER_BASE_URL}${API_V1}${REPORTS_API}/month/${pipelineId}/${userId}`
  )
  return data
}
