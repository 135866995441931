import axiosInstance from './interceptors'
import {
  API_V1,
  CLIENTS_API,
  VUE_APP_SERVER_BASE_URL,
} from '../utils/constants'

// Will only fetch clients with accoding pipeline status
// Not in pipeline clients by default
// TODO: implement separate API calls for general clients list request
// And list filtered by pipeline status
export const getClients = async payload => {
  const { pageNumber, pageSize, order, searchTerm, pipeline_status } = payload
  let url = `${VUE_APP_SERVER_BASE_URL}${API_V1}${CLIENTS_API}/list/by-pipeline-status?page_number=${pageNumber}`

  if (order) url += `&order=${order}`
  if (pageSize !== 'All') url += `&page_size=${pageSize}`
  if (searchTerm) url += `&search_term=${searchTerm}`
  if (pipeline_status) url += `&pipeline_status=${pipeline_status}`
  
  const { data: { data } } = await axiosInstance.get(url)

  return data
}

export const updateClientsDefaultOrder = async payload => {
  await axiosInstance.post(
    `${VUE_APP_SERVER_BASE_URL}${API_V1}${CLIENTS_API}/reorder`,
    payload
  )
}

export const getClientsArchivePage = async payload => {
  const { lastId, pageSize } = payload

  let url = `${VUE_APP_SERVER_BASE_URL}${API_V1}${CLIENTS_API}/archive/list?last_id=${lastId}`

  if (pageSize !== 'All') url += `&page_size=${pageSize}`

  const { data: { data } } = await axiosInstance.get(url)

  return data
}

export const createClient = async client => {
  const { data: { data } } = await axiosInstance.post(
    `${VUE_APP_SERVER_BASE_URL}${API_V1}${CLIENTS_API}/create`,
    client
  )

  return data
}

export const updateClient = async payload => {
  const { clientId, update } = payload
  await axiosInstance.patch(
    `${VUE_APP_SERVER_BASE_URL}${API_V1}${CLIENTS_API}/update/${clientId}`,
    update
  )
}

export const moveClientsToProspects = async payload => {
  const { data: { data } } = await axiosInstance.patch(
    `${VUE_APP_SERVER_BASE_URL}${API_V1}${CLIENTS_API}/move-to-prospect`,
    payload
  ) 
  
  return data
}

export const moveSingleClientToPropsects = async payload => {
  const { data: { data } } = await axiosInstance.patch(
    `${VUE_APP_SERVER_BASE_URL}${API_V1}${CLIENTS_API}/move-one-to-prospect`,
    payload
  )

  return data
}

export const archiveClients = async payload => {
  const { data: { data } } = await axiosInstance.patch(
    `${VUE_APP_SERVER_BASE_URL}${API_V1}${CLIENTS_API}/archive`,
    payload
  )

  return data
}

export const restoreClients = async payload => {
  await axiosInstance.patch(
    `${VUE_APP_SERVER_BASE_URL}${API_V1}${CLIENTS_API}/restore`,
    payload
  )
}

export const deleteClients = async payload => {
  const { data: { data } } = await axiosInstance.delete(
    `${VUE_APP_SERVER_BASE_URL}${API_V1}${CLIENTS_API}/delete`,
    { data: payload }
  )

  return data
}

export const searchArchiveClients = async payload => {
  const { searchValue,pageSize,lastId } = payload
  const { data: { data }} = await axiosInstance.get(
    `${VUE_APP_SERVER_BASE_URL}${API_V1}${CLIENTS_API}/archive/search?last_id=${lastId}&page_size=${pageSize}&search_term=${searchValue}`,
  )

  return data
}