import axiosInstance from './interceptors'
import {
  VUE_APP_SERVER_BASE_URL,
  API_V1,
  PIPELINES_API,
} from '../utils/constants'

export const getActivePipeline = async () => {
  const {
    data: { data },
  } = await axiosInstance.get(
    `${VUE_APP_SERVER_BASE_URL}${API_V1}${PIPELINES_API}/get-active`
  )
  return data
}

export const getPipelinesList = async (lastId) => {
  const {
    data: { data },
  } = await axiosInstance.get(
    `${VUE_APP_SERVER_BASE_URL}${API_V1}${PIPELINES_API}/list?last_id=${lastId}`
  )
  return data
}

export const updatePipelineGoals = async (payload) => {
  await axiosInstance.patch(
    `${VUE_APP_SERVER_BASE_URL}${API_V1}${PIPELINES_API}/update-goals`,
    payload
  )
}

export const getDeals = async (payload) => {
  const { pageNumber, pageSize, dealsType, order, searchTerm } = payload

  let url = `${VUE_APP_SERVER_BASE_URL}${API_V1}${PIPELINES_API}/deals/list?page_number=${pageNumber}&deals_type=${dealsType}`

  if (order) url += `&order=${order}`
  if (pageSize !== 'All') url += `&page_size=${pageSize}`
  if (searchTerm) url += `&search_term=${searchTerm}`

  const {
    data: { data },
  } = await axiosInstance.get(url)

  return data
}

export const addDealWithValue = async (payload) => {
  const {
    data: { data },
  } = await axiosInstance.post(
    `${VUE_APP_SERVER_BASE_URL}${API_V1}${PIPELINES_API}/deals/add/with-value`,
    payload
  )

  return data
}

export const updateDealsDefaultOrder = async (payload) => {
  await axiosInstance.post(
    `${VUE_APP_SERVER_BASE_URL}${API_V1}${PIPELINES_API}/deals/reorder`,
    payload
  )
}

export const getDealBySubject = async (subjectId) => {
  const {
    data: { data },
  } = await axiosInstance.get(
    `${VUE_APP_SERVER_BASE_URL}${API_V1}${PIPELINES_API}/deals/get-by-subject/${subjectId}`
  )

  return data
}

export const addDeals = async (payload) => {
  const {
    data: { data },
  } = await axiosInstance.post(
    `${VUE_APP_SERVER_BASE_URL}${API_V1}${PIPELINES_API}/deals/add`,
    payload
  )

  return data
}

export const recalculatePipelineValue = async () => {
  const {
    data: { data },
  } = await axiosInstance.post(
    `${VUE_APP_SERVER_BASE_URL}${API_V1}${PIPELINES_API}/refresh-pipeline`
  )

  return data
}

export const updateDealValue = async (payload) => {
  const { id, value: new_deal_value } = payload
  const {
    data: { data },
  } = await axiosInstance.patch(
    `${VUE_APP_SERVER_BASE_URL}${API_V1}${PIPELINES_API}/deals/update-value/${id}`,
    { new_deal_value }
  )

  return data
}

export const closeDeal = async (dealId) => {
  const {
    data: { data },
  } = await axiosInstance.patch(
    `${VUE_APP_SERVER_BASE_URL}${API_V1}${PIPELINES_API}/deals/close/${dealId}`
  )

  return data
}

export const openDeal = async (dealId) => {
  const {
    data: { data },
  } = await axiosInstance.patch(
    `${VUE_APP_SERVER_BASE_URL}${API_V1}${PIPELINES_API}/deals/open/${dealId}`
  )

  return data
}

export const removeDeals = async (payload) => {
  const {
    data: { data },
  } = await axiosInstance.delete(
    `${VUE_APP_SERVER_BASE_URL}${API_V1}${PIPELINES_API}/deals/remove`,
    { data: payload }
  )

  return data
}

export const getCompletedDeals = async (payload) => {
  const { pageNumber, pageSize, pipelineTimeStamp } = payload

  let url = `${VUE_APP_SERVER_BASE_URL}${API_V1}${PIPELINES_API}/deals/get-completed?page_number=${pageNumber}&pipeline_timestamp=${pipelineTimeStamp}`

  if (pageSize !== 'All') url += `&page_size=${pageSize}`

  const {
    data: { data },
  } = await axiosInstance.get(url)

  return data
}

export const getUserPipelinesList = async (user_id, last_id) => {
  const {
    data: { data },
  } = await axiosInstance.get(
    `${VUE_APP_SERVER_BASE_URL}${API_V1}${PIPELINES_API}/list/${user_id}`,
    {
      params: { last_id },
    }
  )
  return data
}
