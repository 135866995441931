import * as mutationTypes from './mutationTypes'
import { UserSubscriptionStatus } from '@/utils/constants'

export default {
  // User
  [mutationTypes.SET_CURRENT_USER](state, payload) {
    state.currentUser = payload
  },
  [mutationTypes.CLEAR_CURRENT_USER](state) {
    state.currentUser = null
  },
  [mutationTypes.UPDATE_CURRENT_USER](state, payload) {
    state.currentUser = {
      ...state.currentUser,
      ...payload,
    }
  },
  // Company
  [mutationTypes.SET_COMPANY](state, payload) {
    state.company = payload
  },
  [mutationTypes.UPDATE_COMPANY](state, payload) {
    state.company = {
      ...state.company,
      ...payload,
    }
  },
  [mutationTypes.CLEAR_COMPANY](state) {
    state.company = null
  },
  // Members
  [mutationTypes.UPDATE_MEMBERS_COUNT](state, payload) {
    state.users.membersCount = payload
  },
  [mutationTypes.UPDATE_MEMBERS_LIST](state, payload) {
    state.users.membersList = [...state.users.membersList, ...payload]
  },
  [mutationTypes.CLEAR_ADMIN_MEMBERS](state) {
    state.users.membersList = []
  },
  [mutationTypes.CLEAR_ADMIN_BILLINGS](state, payload) {
    state.billingAdminHistory.billingList = []
  },
  [mutationTypes.ADD_MEMBER](state, payload) {
    state.users.membersList = [payload, ...state.users.membersList]
  },
  [mutationTypes.UPDATE_MEMBER](state, payload) {
    const {
      users: { membersList },
    } = state
    const { member_id: memberId, member_update: memberUpdate } = payload

    delete memberUpdate.password

    const idx = membersList.findIndex((i) => i.id === memberId)
    Object.assign(membersList[idx], memberUpdate)
  },
  [mutationTypes.REMOVE_USERS_FROM_LIST](state, payload) {
    state.users.membersList = state.users.membersList.filter(
      (user) => !payload.includes(user.id)
    )
  },
  [mutationTypes.REPLACE_MEMBERS_LIST](state, payload) {
    state.users.membersList = payload
  },
  [mutationTypes.UPDATE_ATTACHED_USERS](state, payload) {
    state.users.attachedUsersList = payload.members
    state.users.attachedUsernamesList = payload.members.map((user) => user.id)
    state.users.pipelineValue = payload.total_pipeline_value
  },
  [mutationTypes.UPDATE_USERS_TO_ATTACH_LIST](state, payload) {
    state.users.usersToAttach = payload
  },
  // B2B Members
  [mutationTypes.SET_B2B_MEMBERS_LIST](state, payload) {
    state.b2bMembers.list = payload
  },
  [mutationTypes.DELETE_B2B_MEMBER](state, payload) {
    state.b2bMembers.list = state.b2bMembers.list.filter(
      (i) => i.id !== payload
    )
  },
  // Clients
  [mutationTypes.ADD_CLIENT](state, payload) {
    state.clients.clientsList = [payload, ...state.clients.clientsList]
  },
  [mutationTypes.UPDATE_CLIENTS_LIST](state, payload) {
    state.clients.clientsList = [...state.clients.clientsList, ...payload]
  },
  [mutationTypes.REPLACE_CLIENTS_LIST](state, payload) {
    state.clients.clientsList = payload
  },

  [mutationTypes.REPLACE_CLIENTS_ARCHIVE_LIST](state, payload) {
    state.archive.archivedClientsList = payload
  },
  [mutationTypes.UPDATE_CLIENTS_ARCHIVE_LIST](state, payload) {
    state.archive.archivedClientsList = [
      ...state.archive.archivedClientsList,
      ...payload,
    ]
  },
  [mutationTypes.UPDATE_CLIENTS_ARCHIVE_COUNT](state, payload) {
    state.archive.archivedClientsCount = payload
  },
  [mutationTypes.UPDATE_CLIENTS_COUNT](state, payload) {
    state.clients.clientsCount = payload
  },
  [mutationTypes.UPDATE_CLIENT](state, payload) {
    const clientIndex = state.clients.clientsList.findIndex(
      (client) => client.id === payload.id
    )
    Object.assign(state.clients.clientsList[clientIndex], payload)
  },
  [mutationTypes.REMOVE_CLIENTS_FROM_LIST](state, payload) {
    state.clients.clientsList = state.clients.clientsList.filter(
      (client) => !payload.includes(client.id)
    )
  },
  [mutationTypes.REMOVE_CLIENTS_FROM_ARCHIVE_LIST](state, payload) {
    state.archive.archivedClientsList =
      state.archive.archivedClientsList.filter(
        (client) => !payload.includes(client.id)
      )
  },
  [mutationTypes.CLEAR_CLIENTS](state) {
    state.clients.clientsList = []
  },
  [mutationTypes.CLEAR_ARCHIVE_CLIENTS](state) {
    state.archive.archivedClientsList = []
  },
  // Prospects
  [mutationTypes.ADD_PROSPECT](state, payload) {
    state.prospects.prospectsList = [payload, ...state.prospects.prospectsList]
  },
  [mutationTypes.CLEAR_ARCHIVE_PROSPECTS](state) {
    state.archive.archivedProspectsList = []
  },
  [mutationTypes.UPDATE_PROSPECTS_LIST](state, payload) {
    state.prospects.prospectsList = [
      ...state.prospects.prospectsList,
      ...payload,
    ]
  },
  [mutationTypes.REPLACE_PROSPECTS_LIST](state, payload) {
    state.prospects.prospectsList = payload
  },
  [mutationTypes.REPLACE_PROSPECTS_ARCHIVE_LIST](state, payload) {
    state.archive.archivedProspectsList = payload
  },
  [mutationTypes.UPDATE_PROSPECTS_ARCHIVE_LIST](state, payload) {
    state.archive.archivedProspectsList = [
      ...state.archive.archivedProspectsList,
      ...payload,
    ]
  },
  [mutationTypes.UPDATE_PROSPECTS_ARCHIVE_COUNT](state, payload) {
    state.archive.archivedProspectsCount = payload
  },
  [mutationTypes.UPDATE_PROSPECTS_COUNT](state, payload) {
    state.prospects.prospectsCount = payload
  },
  [mutationTypes.UPDATE_PROSPECT](state, payload) {
    const prospectIndex = state.prospects.prospectsList.findIndex(
      (prospect) => prospect.id === payload.id
    )
    Object.assign(state.prospects.prospectsList[prospectIndex], payload)
  },
  [mutationTypes.REMOVE_PROSPECTS_FROM_LIST](state, payload) {
    state.prospects.prospectsList = state.prospects.prospectsList.filter(
      (prospect) => !payload.includes(prospect.id)
    )
  },
  [mutationTypes.CLEAR_PROSPECTS](state) {
    state.prospects.prospectsList = []
  },
  [mutationTypes.REMOVE_PROSPECTS_FROM_ARCHIVE_LIST](state, payload) {
    state.archive.archivedProspectsList =
      state.archive.archivedProspectsList.filter(
        (prospect) => !payload.includes(prospect.id)
      )
  },
  // General pipeline mutations
  [mutationTypes.SET_PIPELINE_LIST](state, payload) {
    state.pipelines.pipelinesList = payload
  },
  [mutationTypes.UPDATE_PIPELINES_COUNT](state, payload) {
    state.pipelines.pipelinesCount = payload
  },
  [mutationTypes.UPDATE_PIPELINES_LIST](state, payload) {
    state.pipelines.pipelinesList = [
      ...state.pipelines.pipelinesList,
      ...payload,
    ]
  },
  [mutationTypes.SET_ACTIVE_PIPELINE](state, payload) {
    state.activePipeline = payload
  },
  [mutationTypes.UPDATE_PIPELINE_FIELDS](state, payload) {
    state.activePipeline = {
      ...state.activePipeline,
      ...payload,
    }
  },
  [mutationTypes.UPDATE_PIPELINE_VALUE](state, payload) {
    state.activePipeline.value = payload
  },
  [mutationTypes.UPDATE_PIPELINE_CLOSED_VALUE](state, payload) {
    state.activePipeline.closed_value = payload
  },
  // Clients pipeline
  [mutationTypes.ADD_CLIENT_DEAL](state, payload) {
    state.clientDeals.clientDealsList = [
      payload,
      ...state.clientDeals.clientDealsList,
    ]
  },
  [mutationTypes.UPDATE_CLIENT_DEALS_LIST](state, payload) {
    state.clientDeals.clientDealsList = [
      ...state.clientDeals.clientDealsList,
      ...payload,
    ]
  },
  [mutationTypes.REPLACE_CLIENT_DEALS_LIST](state, payload) {
    state.clientDeals.clientDealsList = payload
  },
  [mutationTypes.UPDATE_CLIENT_DEALS_COUNT](state, payload) {
    state.clientDeals.clientDealsCount = payload
  },
  [mutationTypes.UPDATE_CLIENT_DEAL](state, payload) {
    const clientDealIndex = state.clientDeals.clientDealsList.findIndex(
      (deal) => deal.id === payload.id
    )
    Object.assign(state.clientDeals.clientDealsList[clientDealIndex], payload)
  },
  [mutationTypes.REMOVE_CLIENT_DEALS](state, payload) {
    state.clientDeals.clientDealsList =
      state.clientDeals.clientDealsList.filter(
        (deal) => !payload.includes(deal.id)
      )
  },
  [mutationTypes.CLEAR_CLIENT_DEALS](state) {
    state.clientDeals.clientDealsList = []
    state.clientDeals.clientDealsCount = 0
  },
  // Prospects pipeline
  [mutationTypes.ADD_HOT_DEAL](state, payload) {
    state.hotDeals.hotDealsList = [payload, ...state.hotDeals.hotDealsList]
  },
  [mutationTypes.REPLACE_HOT_DEALS](state, payload) {
    state.hotDeals.hotDealsList = payload
  },
  [mutationTypes.SET_HOT_DEALS_LIST](state, payload) {
    state.hotDeals.hotDealsList = payload
  },
  [mutationTypes.UPDATE_HOT_DEALS_LIST](state, payload) {
    state.hotDeals.hotDealsList = [...state.hotDeals.hotDealsList, ...payload]
  },
  [mutationTypes.UPDATE_HOT_DEALS_COUNT](state, payload) {
    state.hotDeals.hotDealsCount = payload
  },
  [mutationTypes.UPDATE_HOT_DEAL](state, payload) {
    const hotDealIndex = state.hotDeals.hotDealsList.findIndex(
      (deal) => deal.id === payload.id
    )
    Object.assign(state.hotDeals.hotDealsList[hotDealIndex], payload)
  },
  [mutationTypes.REMOVE_HOT_DEALS](state, payload) {
    state.hotDeals.hotDealsList = state.hotDeals.hotDealsList.filter(
      (deal) => !payload.includes(deal.id)
    )
  },
  [mutationTypes.CLEAR_HOT_DEALS](state) {
    state.hotDeals.hotDealsList = []
  },
  [mutationTypes.ADD_IN_PROGRESS_DEAL](state, payload) {
    state.inProgressDeals.inProgressDealsList = [
      payload,
      ...state.inProgressDeals.inProgressDealsList,
    ]
  },
  [mutationTypes.SET_IN_PROGRESS_DEALS_LIST](state, payload) {
    state.inProgressDeals.inProgressDealsList = payload
  },
  [mutationTypes.UPDATE_IN_PROGRESS_DEALS_LIST](state, payload) {
    state.inProgressDeals.inProgressDealsList = [
      ...state.inProgressDeals.inProgressDealsList,
      ...payload,
    ]
  },
  [mutationTypes.UPDATE_IN_PROGRESS_DEALS_COUNT](state, payload) {
    state.inProgressDeals.inProgressDealsCount = payload
  },
  [mutationTypes.UPDATE_IN_PROGRESS_DEAL](state, payload) {
    const inProgressDealIndex =
      state.inProgressDeals.inProgressDealsList.findIndex(
        (deal) => deal.id === payload.id
      )
    Object.assign(
      state.inProgressDeals.inProgressDealsList[inProgressDealIndex],
      payload
    )
  },
  [mutationTypes.REMOVE_IN_PROGRESS_DEALS](state, payload) {
    state.inProgressDeals.inProgressDealsList =
      state.inProgressDeals.inProgressDealsList.filter(
        (deal) => !payload.includes(deal.id)
      )
  },
  [mutationTypes.CLEAR_IN_PROGRESS_DEALS](state) {
    state.inProgressDeals.inProgressDealsList = []
  },
  [mutationTypes.ADD_TARGET_DEAL](state, payload) {
    state.targetDeals.targetDealsList = [
      payload,
      ...state.targetDeals.targetDealsList,
    ]
  },
  [mutationTypes.SET_TARGET_DEALS_LIST](state, payload) {
    state.targetDeals.targetDealsList = payload
  },
  [mutationTypes.UPDATE_TARGET_DEALS_LIST](state, payload) {
    state.targetDeals.targetDealsList = [
      ...state.targetDeals.targetDealsList,
      ...payload,
    ]
  },
  [mutationTypes.UPDATE_TARGET_DEALS_COUNT](state, payload) {
    state.targetDeals.targetDealsCount = payload
  },
  [mutationTypes.UPDATE_TARGET_DEAL](state, payload) {
    const targetDealIndex = state.targetDeals.targetDealsList.findIndex(
      (deal) => deal.id === payload.id
    )
    Object.assign(state.targetDeals.targetDealsList[targetDealIndex], payload)
  },
  [mutationTypes.REMOVE_TARGET_DEALS](state, payload) {
    state.targetDeals.targetDealsList =
      state.targetDeals.targetDealsList.filter(
        (deal) => !payload.includes(deal.id)
      )
  },
  [mutationTypes.CLEAR_TARGET_DEALS](state) {
    state.targetDeals.targetDealsList = []
  },
  [mutationTypes.CLEAR_ALL_PROSPECT_DEALS](state) {
    state.hotDeals.hotDealsList = []
    state.hotDeals.hotDealsCount = 0
    state.inProgressDeals.inProgressDealsList = []
    state.inProgressDeals.inProgressDealsCount = 0
    state.targetDeals.targetDealsList = []
    state.targetDeals.targetDealsCount = 0
  },
  // Completed Sales
  [mutationTypes.UPDATE_COMPLETED_SALES_LIST](state, payload) {
    state.completedSales.completedSalesList = [
      ...state.completedSales.completedSalesList,
      ...payload,
    ]
  },
  [mutationTypes.UPDATE_COMPLETED_SALES_COUNT](state, payload) {
    state.completedSales.completedSalesCount = payload
  },
  [mutationTypes.CLEAR_COMPLETED_SALES](state) {
    state.completedSales.completedSalesList = []
    state.completedSales.completedSalesCount = 0
  },
  // Subscriptions
  [mutationTypes.REPLACE_BILLINGS_LIST](state, payload) {
    state.billingAdminHistory.billingList = payload
  },
  [mutationTypes.GRANT_SUBSCRIPTION](state, payload) {
    const { memberId, dateExpire } = payload
    let userSubscription = state.users.membersList.find(
      (item) => item.id === memberId
    ).subscription

    userSubscription.current_period_end = dateExpire || null
    userSubscription.status = dateExpire
      ? UserSubscriptionStatus['Granted Until']
      : UserSubscriptionStatus.Granted
  },
  [mutationTypes.REVOKE_SUBSCRIPTION](state, payload) {
    const userIndex = state.users.membersList.findIndex(
      (item) => item.id === payload
    )
    state.users.membersList[userIndex].subscription.status =
      UserSubscriptionStatus.Stopped
  },
  [mutationTypes.UPDATE_BILLINGS_LIST](state, payload) {
    state.billingAdminHistory.billingList = [
      ...state.billingAdminHistory.billingList,
      ...payload,
    ]
  },
  [mutationTypes.UPDATE_BILLINGS_COUNT](state, payload) {
    state.billingAdminHistory.billingCount = payload
  },
  [mutationTypes.SET_CARD_LIST](state, payload) {
    state.cardsList = payload
  },
  [mutationTypes.ADD_CARD](state, payload) {
    state.cardsList.push(payload)
  },
  [mutationTypes.DELETE_CARD](state, payload) {
    state.cardsList = state.cardsList.filter((i) => i.id !== payload)
  },
  // UndoRedo
  [mutationTypes.UNDO_REDO_UPDATE_CLIENT](state, payload) {
    const clientIndex = state.clients.clientsList.findIndex(
      (client) => client.id === payload.id
    )
    Object.assign(state.clients.clientsList[clientIndex], payload)
  },
}
